import React from 'react'
import styled from 'styled-components'
import Address from '../footer/Address'
import GoogleMaps from '../GoogleMaps'

const HomeSectionFour = () => {
  return (
    <Wrapper>
      <div className='box box-1'>
        <Address />
      </div>
      <div className='box box-2'>
        <GoogleMaps />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;

  .box {
    height: 50vh;
  }
  .box-1 {
    display: grid;
    align-content: center;
  }
  .box-2 {
    display: grid;
    align-content: center;
  }
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .box {
      height: 100vh;
    }
  }
`
export default HomeSectionFour
