import React from 'react'
import { Helmet } from 'react-helmet-async'

import Home from '../components/landingPage/Home'
import HomeSectionFive from '../components/landingPage/HomeSectionFive'
import HomeSectionFour from '../components/landingPage/HomeSectionFour'
import HomeSectionThree from '../components/landingPage/HomeSectionThree'
import HomeSectionTwo from '../components/landingPage/HomeSectionTwo'

const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>Real Pure Water</title>
        <meta
          name='description'
          content='U-fill water, R/O (reverse osmosis) and alkaline water serving Niagara + water delivery service.'
        />
        <link rel='canonical' href='/' />
      </Helmet>
      <Home />
      <HomeSectionTwo />
      <HomeSectionThree />
      <HomeSectionFour />
      <HomeSectionFive />
    </>
  )
}

export default LandingPage
