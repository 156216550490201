import {
  FaHome,
  FaEnvelope,
  FaRegAddressBook,
  FaInstagramSquare,
  FaFacebook,
  FaTwitterSquare,
} from 'react-icons/fa'
// ========logo =========== //
export const logo =
  'https://res.cloudinary.com/inam6530/image/upload/v1668529151/Real%20Pure%20Water/logo_iddulg.png'
// ========Nav Bar=========== //

export const navbar = [
  { id: 1, path: '/', title: 'Home', icon: <FaHome /> },
  {
    id: 2,
    path: '/productS/OSMOSIS',
    title: 'Water Quality',
    icon: <FaEnvelope />,
  },
  { id: 3, path: '/contact', title: 'Contact Us', icon: <FaRegAddressBook /> },
]
// ========Landing page Section # 1======== //
export const LandingImage =
  'https://res.cloudinary.com/inam6530/image/upload/v1668549280/Real%20Pure%20Water/Untitled_a3pllm.png'

// ========Landing page Section # 2======== //

export const landingSectionTwo = {
  backgroundImage:
    'https://res.cloudinary.com/inam6530/image/upload/v1667925181/Real%20Pure%20Water/1_mxlfca.svg',
  waterJug:
    'https://res.cloudinary.com/inam6530/image/upload/v1667925180/Real%20Pure%20Water/waterJugs_al57sk.png',
  waterTruck:
    'https://res.cloudinary.com/inam6530/image/upload/v1667925176/Real%20Pure%20Water/4_xlapkg.svg',
}
// ========landing section # 3========
export const landingSectionThree = {
  backgroundImage:
    'https://res.cloudinary.com/inam6530/image/upload/v1667935287/Real%20Pure%20Water/Untitled_wcp3cc.png',
}
// ========landing section # 5========
export const landingSectionFive = {
  backgroundImage:
    'https://res.cloudinary.com/inam6530/image/upload/v1668000860/Real%20Pure%20Water/Map_ztqmlr.png',
}
// ============Single page============
export const singlePageData = [
  {
    id: 1,
    productParam: 'OSMOSIS',
    productName: 'REVERSE OSMOSIS PACKAGES',
    packageName: 'Buy 6 + 1 Free = ',
    packageValue18L: 7,
    packageValue10L: 12.6,
    packageValue11L: 11.5,
    packagePrice: '$23.95',
    packageLiters: 126,
    packagePricePerJug: '$3.42',
    productImage:
      'https://res.cloudinary.com/inam6530/image/upload/v1667925180/Real%20Pure%20Water/waterJugs_al57sk.png',
  },
  {
    id: 2,
    productParam: 'OSMOSIS',
    productName: 'REVERSE OSMOSIS PACKAGES',
    packageName: 'Buy 10 + 2 Free = ',
    packageValue18L: 12,
    packageValue10L: 21.6,
    packageValue11L: 19.6,
    packagePrice: '$39.90',
    packageLiters: 216,
    packagePricePerJug: '$3.32',
    productImage:
      'https://res.cloudinary.com/inam6530/image/upload/v1667925180/Real%20Pure%20Water/waterJugs_al57sk.png',
  },
  {
    id: 3,
    productParam: 'OSMOSIS',
    productName: 'REVERSE OSMOSIS PACKAGES',
    packageName: 'Buy 20 + 5 Free = ',
    packageValue18L: 25,
    packageValue10L: 45.0,
    packageValue11L: 40.9,
    packagePrice: '$79.80',
    packageLiters: 450,
    packagePricePerJug: '$3.19',
    productImage:
      'https://res.cloudinary.com/inam6530/image/upload/v1667925180/Real%20Pure%20Water/waterJugs_al57sk.png',
  },
  {
    id: 3.2,
    productParam: 'OSMOSIS',
    productName: 'REVERSE OSMOSIS PACKAGES',
    packageName: '18 Liter ',
    packageValue18L: '',
    packageValue10L: '',
    packageValue11L: '$2.50',
    packagePrice: '',
    packageLiters: '',
    packagePricePerJug: '$4.00',
    productImage:
      'https://res.cloudinary.com/inam6530/image/upload/v1667925180/Real%20Pure%20Water/waterJugs_al57sk.png',
  },
  {
    id: 4,
    productParam: 'ALKALINE',
    productName: 'ALKALINE PACKAGES',
    packageName: 'Buy 5 + 1 Free = ',
    packageValue18L: 6,
    packageValue10L: 10.8,
    packageValue11L: 9.8,
    packagePrice: '$28.75',
    packageLiters: 108,
    packagePricePerJug: '$4.80',
    productImage:
      'https://res.cloudinary.com/inam6530/image/upload/v1667925180/Real%20Pure%20Water/waterJugs_al57sk.png',
  },
  {
    id: 5,
    productParam: 'ALKALINE',
    productName: 'ALKALINE PACKAGES',
    packageName: 'Buy 10 + 2 Free = ',
    packageValue18L: 12,
    packageValue10L: 21.6,
    packageValue11L: 19.6,
    packagePrice: '$57.50',
    packageLiters: 216,
    packagePricePerJug: '$4.79',
    productImage:
      'https://res.cloudinary.com/inam6530/image/upload/v1667925180/Real%20Pure%20Water/waterJugs_al57sk.png',
  },
  {
    id: 6,
    productParam: 'ALKALINE',
    productName: 'ALKALINE PACKAGES',
    packageName: 'Buy 20 + 5 Free = ',
    packageValue18L: 25,
    packageValue10L: 45.0,
    packageValue11L: 40.9,
    packagePrice: '$115.00',
    packageLiters: 450,
    packagePricePerJug: '$4.60',
    productImage:
      'https://res.cloudinary.com/inam6530/image/upload/v1667925180/Real%20Pure%20Water/waterJugs_al57sk.png',
  },
  {
    id: 6.2,
    productParam: 'ALKALINE',
    productName: 'ALKALINE PACKAGES',
    packageName: '18 Liter ',
    packageValue18L: '',
    packageValue10L: '',
    packageValue11L: '$3.50',
    packagePrice: '',
    packageLiters: '',
    packagePricePerJug: '$6.00',
    productImage:
      'https://res.cloudinary.com/inam6530/image/upload/v1667925180/Real%20Pure%20Water/waterJugs_al57sk.png',
  },
  {
    id: 7,
    productParam: 'DELIVERY',
    productName: 'Delivery Services',
    packageName: 'Coming SOON...',
    packageValue18L: '',
    packageValue10L: '',
    packageValue11L: '',
    packagePrice: '',
    packageLiters: '',
    packagePricePerJug: '',
    productImage:
      'https://res.cloudinary.com/inam6530/image/upload/v1667925176/Real%20Pure%20Water/4_xlapkg.svg',
  },
]

// ===========footer //============
// ===== Data for Address =====
export const address = [
  {
    id: 1,
    title: 'Address',
    titleInfo: 'https://www.google.com/maps/@43.1121588,-79.100695,17z',
    titleText: '4226 Drummond Rd., Niagara Falls, ON L2E 6B9',
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    id: 2,
    title: 'Store',
    titleInfo: 'tel:9053543971',
    titleText: '905-354-3971',
  },
  {
    id: 3,
    title: 'Cell',
    titleInfo: 'tel:2892285679',
    titleText: '289-228-5679',
  },
  {
    id: 4,
    title: 'Email',
    titleInfo: 'mailto:brian.realpurewater@gmail.com',
    titleText: 'brian.realpurewater@gmail.com',
  },
]
// ======Data for SocialIcons=====Start
export const socialIcons = [
  {
    id: 1,
    path: 'https://facebook.com',
    target: '_blank',
    rel: 'noreferrer',
    icon: <FaFacebook />,
    title: 'Facebook',
  },
  {
    id: 2,
    path: 'https://Twitter.com',
    target: '_blank',
    rel: 'noreferrer',
    icon: <FaTwitterSquare />,
    title: 'Twitter',
  },
  {
    id: 5,
    path: 'https://www.instagram.com/',
    target: '_blank',
    rel: 'noreferrer',
    icon: <FaInstagramSquare />,
    title: 'Instagram',
  },
]
// ======== data for google maps==========

export const googleMapsData = { lat: 43.11207, lng: -79.09823 }
