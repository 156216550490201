import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { navbar } from '../../utils/data'
import Logo from '../Logo'

const DesktopNavbar = () => {
  return (
    <Wrapper>
      <Logo />
      <ul>
        {navbar.map((item, index) => {
          return (
            <li key={index}>
              <NavLink to={item.path}>{item.title}</NavLink>
            </li>
          )
        })}
      </ul>
      <div className='question'>
        <p>
          <strong>Questions?</strong>{' '}
        </p>
        <a href='tel:9053543971'>9053543971</a>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  height: 5.2rem;
  background-color: var(--white);
  display: flex;
  text-align: center;

  width: auto;
  ul {
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: end;
    li {
      a {
        margin-right: 1rem;
        color: var(--black);

        :hover {
          border-bottom: 5px solid var(--primary-5);
        }
      }
    }
  }

  /* nav active */
  .active {
    border-bottom: 5px solid var(--primary-5);
  }
  /* question */
  .question {
    display: flex;
    align-items: center;
    align-items: flex-end;
    margin-right: 1rem;
    p {
      margin-bottom: 0.5rem;
      margin-right: 1rem;
    }
    a {
      margin-bottom: 0.5rem;
    }
    p,
    a {
      font-size: 1.5rem;
      color: black;
    }
  }
  @media (max-width: 992px) {
    .question {
      display: none;
    }
  }
`
export default DesktopNavbar
