import React from 'react'
import styled from 'styled-components'
import { LandingImage } from '../../utils/data'

const Home = () => {
  return (
    <Wrapper>
      <div className='box-1'>
        <h1>Real pure water</h1>
        <div className='title-underline'></div>
        <div className='box-1-image'>
          <img src={LandingImage} alt='Van' />
        </div>
        <p>We deliver quality and pure water around Niagara Region.</p>
      </div>
      <div className='box-2'>
        <img src={LandingImage} alt='Van' />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: calc(100vh - 5.2rem);

  gap: 1rem;

  .box-1 {
    display: grid;
    text-align: center;
    align-content: center;
    justify-content: center;
    padding: 1rem;

    background: linear-gradient(90deg, #dff2fd 0%, #ebf9fc 50%, #f8fafc 100%);
  }
  .box-2 {
    display: none;
    animation-name: slide-in-right;
    animation: slide-in-right 1.7s ease 0s 1 normal none;

    img {
      max-width: 800px;
    }
  }
  /* box-1-image */
  .box-1-image {
    display: none;
  }
  @media (max-width: 992px) {
    display: grid;
    .box-1-image {
      text-align: center;
      display: block;
      img {
        max-width: 350px;
      }
    }
  }
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .box-2 {
      display: block;
    }
  }
  @keyframes slide-in-right {
    0% {
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
`
export default Home
