import React from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { singlePageData } from '../utils/data'

const SingleProduct = () => {
  const params = useParams()
  console.log(params.id)
  const singleProduct = singlePageData.filter(
    (item) => item.productParam === params.id
  )
  const image = singleProduct[0].productImage

  return (
    <Wrapper>
      <div
        style={{ backgroundImage: `url(${image})` }}
        className='box box-1'
      ></div>
      <div className='box box-2'>
        <h1 className='title'>{singleProduct[0].productName}</h1>
        <table>
          <tbody>
            <tr className='table-header'>
              <th>18 LITRE PACKAGES</th>
              <th>PRICE</th>
              <th>LITRES</th>
              <th>$ / JUG</th>
              <th>10 L</th>
              <th>11 L</th>
            </tr>
            {singleProduct.map((item) => {
              console.log(item)
              const {
                packageName,
                packageValue18L,
                packageValue10L,
                packageValue11L,
                packagePrice,
                packageLiters,
                packagePricePerJug,
              } = item
              return (
                <tr className='table-data' key={item.id}>
                  <td className='package'>
                    {packageName} {packageValue18L}
                  </td>
                  <td>{packagePrice}</td>
                  <td>{packageLiters}</td>
                  <td>{packagePricePerJug}</td>
                  <td>{packageValue10L}</td>
                  <td>{packageValue11L}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className='btn-container'>
          <Link className='btn ' to={'/'}>
            Home page
          </Link>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  .box {
    min-height: 50vh;
  }
  .box-1 {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .table-data:last-child {
    border: 2px solid orange;
    color: red;
  }
  .package {
    color: black;
  }
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    .box-2 {
      padding: 2rem;
    }
  }
  h1 {
    font-size: 2rem;
  }
  .btn-container {
    margin-top: 1rem;
  }
  /* table css */

  .table-header {
    background-color: #3787c0;
    color: #fff;
  }
  .table-data {
    text-align: center;
  }
`

export default SingleProduct
