import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { landingSectionTwo } from '../../utils/data'
const { backgroundImage, waterJug, waterTruck } = landingSectionTwo
const data = [
  {
    id: 1,
    title: 'REVERSE OSMOSIS',
    productName: 'OSMOSIS',
    className: 'box-1 box',
    image: waterJug,
  },
  {
    id: 2,
    productName: 'ALKALINE',
    title: 'ALKALINE PH9',
    className: 'box-2 box',
    image: waterJug,
  },
  {
    id: 3,
    productName: 'DELIVERY',
    title: 'BULK WATER CISTERNS, POOLS',
    className: 'box-3 box',
    image: waterTruck,
  },
]

const HomeSectionTwo = () => {
  const navigate = useNavigate()
  const handleClick = (id) => {
    navigate(`products/${id}`)
  }
  return (
    <Wrapper>
      <img className='bg-image' src={backgroundImage} alt='water' />
      <div className='container'>
        {data.map((item, index) => {
          const { id, productName } = item
          return (
            <div
              onClick={() => handleClick(productName)}
              key={id}
              className={item.className}
            >
              <h2>{item.title}</h2>
              <img src={item.image} alt='water Jug' />
              <p>
                <strong>Order Now</strong>
              </p>
            </div>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  min-height: 100vh;

  .bg-image {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }
  @media (max-width: 992px) {
    .bg-image {
      display: none;
    }
    .container {
      flex-wrap: wrap;
    }
  }

  /* container box */
  .container {
    display: flex;
    min-height: 100vh;
    justify-content: space-evenly;
    align-items: flex-start;

    h2 {
      max-width: 350px;
      margin: 0px;
      font-size: 1.5rem;
    }
    p {
      margin: 0;
      font-size: 1.5rem;
      background-color: var(--grey-3);
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
      transition: var(--transition);
    }
    img {
      transition: var(--transition);
    }
  }
  .box {
    min-width: 320px;
    min-height: 280px;
    border: 5px solid var(--primary-9);
    margin-top: 0.5rem;
    text-align: center;
    margin-right: 0.5rem;
    border-radius: 2rem;
    transition: var(--transition);
    :hover {
      cursor: pointer;
      p {
        background-color: #e6f3f9;
      }
      img {
        transform: scale(1.1);
      }
    }

    img {
      height: 200px;
      width: auto;
    }
  }
  .box-3 {
    img {
      width: 300px;
    }
    p {
      margin-top: -2rem;
    }
  }
`
export default HomeSectionTwo
