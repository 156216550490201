import React from 'react'
import styled from 'styled-components'
import { landingSectionFive } from '../../utils/data'
const { backgroundImage } = landingSectionFive

const HomeSectionFive = () => {
  return (
    <Wrapper>
      <div className='box box-2'>
        <h3>Service Area</h3>
        <div className='title-underline'></div>
        <p>
          <strong>Real Pure Water</strong> deliver water around Niagara Region.
        </p>
      </div>
      <div className='box box-1'></div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: var(--white);
  .box {
    min-height: 100vh;
  }
  @media (max-width: 992px) {
    .box-2 {
      display: none;
    }
    min-height: 50vh;
    .box-1 {
      min-height: 50vh;
    }
  }
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .box-2 {
      display: grid;
      align-content: center;
      justify-items: center;
      padding: 5rem;
      background: linear-gradient(90deg, #dff2fd 0%, #ebf9fc 50%, #fff 100%);
    }
  }
  /* background image */
  .box-1 {
    background-image: url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: var(--white);
  }
`
export default HomeSectionFive
